<script lang="ts" setup>
import { useProfileProductId } from '@/shared/stores/ProfileProductId';
import { COMPANY } from '@shared/constants/ProfileProductId';
import { useProfileStore } from '@/modules/rating-detail/stores/ProfileStore';
import VerifiedBadge from '@/modules/rating-detail/components/Badges/VerifiedBadge.vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { AnwAvatar } from '@anwalt.de/tailwind-design-system';
import { useImage } from '@shared/composables/image';

const { profileProductId } = storeToRefs(useProfileProductId());
const { displayedName } = useProfileStore();
const { t } = useI18n();
const { imagePath } = storeToRefs(useProfileStore());
const imageUrlHost = import.meta.env.VITE_IMAGES_URL;
const { computedProfileImagePath } = useImage(imageUrlHost, imagePath.value, profileProductId.value);
</script>

<template>
  <div class="p-4 flex justify-items-start">
    <div class="relative flex gap-4 xl:items-center">
      <figure class="flex-shrink-0">
        <AnwAvatar
          :alt="t('profileImage', { name: displayedName })"
          :src="computedProfileImagePath(80)"
          size="lg"
          :aspect="profileProductId === COMPANY ? 'auto' : 'square'"
          class="object-cover object-center" />
      </figure>
      <div>
        <div class="flex gap-2 items-center text-neutral-600 mb-1.5">
          <VerifiedBadge />
        </div>
        <h2 class="anw-h5 font-bold anw-hyphens mb-0">{{ displayedName }}</h2>
      </div>
    </div>
  </div>
</template>
